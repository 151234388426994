import {Config} from "../../../utils/Config";

export const openOfficeStatsWebSocket = (id, setData) => {
    let socket = new WebSocket(Config.socketURL + "ws/ws-office-stats/" + id)

    socket.onopen = function(e) {
        console.log('Opened ' + id)
    };
    socket.onmessage = function(event) {
        let data = JSON.parse(event.data)
        console.log("Data:", data)
        setData(data)
    };

    socket.onclose = function(event) {
        console.log('Closed '+ id)
        if (event.wasClean) {
            // Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
            openOfficeStatsWebSocket(id, setData)
        }
    };

    socket.onerror = function(error) {
        console.log('Error '+ id)
        openOfficeStatsWebSocket(id, setData)
    };
}

