import {Button, Card, Col, Form, Input, message, Row, Select} from "antd";
import {createOfficeRequest, createOrganization, createProviderRequest, loginRequest} from "../../utils/API";
import {useEffect, useState} from "react";
import {Content} from "antd/es/layout/layout";
import * as PropTypes from "prop-types";
import {Option} from "antd/es/mentions";
import {useNavigate} from "react-router-dom";
import Authenticator from "../Authentications/Authenticator";
import {loadDistricts, loadOrganizations, loadRegions} from "../../utils/commonFunctions";


const CreateOffice = () => {
    const [loading, setLoading] = useState(false)
    const [organizations, setOrganizations] = useState([])
    const [regions, setRegions] = useState([])
    const [organization, setOrganization] = useState()
    const [districts, setDistricts] = useState([])

    const navigate = useNavigate()
    const onFinish = (values) => {
        console.log("Success:", values);
        createOfficeRequest(values)
            .then((response) => {
                console.log(response)
                if (response.status){
                    message.success(values.name + " was successfully created").then()
                    navigate('/offices', {replace: true})
                }
                else {
                    message.warning('Data Provided is not correct, check and try again!', 7).then()
                }

            })
            .catch((err) => {
                console.log(err)
                message.error("Error occurred").then()
            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const handleSearchOrganizations = (newValue) => {
        if (newValue) {
            loadOrganizations(newValue, setOrganizations);
        } else {
            setOrganizations([]);
        }
    };
    const handleChange = (e) => {
        console.log(e)
        setOrganization(e)
    }

    useEffect(() => {
        // getDashboardData().then();
        Authenticator(navigate)
        loadRegions(setRegions)
    }, [])

    return (
        <Card>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="horizontal"

            >
                <Row gutter={16}>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            className="username"
                            label="Choose Organization"
                            name="organization"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Select the Organization!",
                                },
                            ]}
                        >
                        <Select
                            showSearch
                            value={organization}
                            placeholder="Select Organization"
                            // style={props.style}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={handleSearchOrganizations}
                            onChange={handleChange}
                            notFoundContent={null}
                        >
                            {
                                organizations.map((organization, idx) => {
                                    return (
                                        <Option key={organization.value}>{organization.label}</Option>
                                    )
                                })
                            }
                        </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            className="username"
                            label="Office Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the Organization's name!",
                                },
                            ]}
                        >
                            <Input placeholder="eg. CRDB Bank LTD" type="text"/>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            className="username"
                            label="Office Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the Organization's email!",
                                },
                            ]}
                        >
                            <Input placeholder="eg. example@crdbtz.co.tz" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            className="username"
                            label="Office Phone"
                            name="phone_no"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input Organization's Phone Number!",
                                },
                            ]}
                        >
                            <Input placeholder="eg. 0714321121" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            className="username"
                            label="City"
                            name="city"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Select City!",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                style={{
                                    width: "100%",
                                }}
                                size={"large"}
                                placeholder="Select Region"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={(e) => {
                                    loadDistricts(e, setDistricts)
                                }}
                            >
                                {regions.map((region, idx) => {
                                    return (
                                        <Option key={idx} value={region.id}>{region.name}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            className="username"
                            label="District"
                            name="district"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Select District!",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                style={{
                                    width: "100%",
                                    borderRadius: "20px"
                                }}
                                name="district"
                                size={"large"}
                                placeholder="Select Region"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {districts.map((district, idx) => {
                                    return (
                                        <Option key={idx} value={district.id}>{district.name}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            className="username"
                            label="Office's Address"
                            name="physical_address"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the Organization's HeadQuarter Address!",
                                },
                            ]}
                        >
                            <Input placeholder="i.e Floor, Building, Place" type="text"/>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            className="username"
                            label="Postal Address"
                            name="postal_address"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input Organization's Postal Address!",
                                },
                            ]}
                        >
                            <Input placeholder="e.g P.O.Box xxxxx" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end">
                    <Form.Item label=" " colon={false}>
                        <Button type="primary" loading={loading} htmlType="submit" style={{ width: "200px" }}>
                            Submit Data
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Card>
    )
}

export default CreateOffice