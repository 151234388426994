/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactApexChart from "react-apexcharts";
import {Card, Col, Row, Typography} from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "../../../components/chart/configs/lineChart";
import {useEffect, useState} from "react";
import Authenticator from "../../Authentications/Authenticator";
import {monthRange} from "../../../utils/functions/common";
import {organizationStatsRequest} from "../../../utils/API";

const data = {
    series: [
        {
            name: "GNM Cargo Kariakoo",
            data: [350, 40, 300, 220, 500, 250, 400, 230, 500],
            offsetY: 0,
        },
        {
            name: "Websites",
            data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
            offsetY: 0,
        },
        {
            name: "Websites",
            data: [30, 100, 38, 50, 290, 2, 340, 432, 333],
            offsetY: 0,
        },
    ],

    options: {
        chart: {
            width: "100%",
            height: 350,
            type: "area",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            area: {
                colors: [
                    "#008efa",
                    "#00e196",
                    "#fcaf1a",
                ], // Set colors for each y value
            },
        },

        legend: {
            show: false,
        },

        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
        },

        yaxis: {
            labels: {
                style: {
                    fontSize: "14px",
                    fontWeight: 600,
                    colors: ["#8c8c8c"],
                },
            },
        },

        xaxis: {
            labels: {
                style: {
                    fontSize: "14px",
                    fontWeight: 600,
                    colors: [
                        "#8c8c8c",
                        "#8c8c8c",
                        "#8c8c8c",
                        "#8c8c8c",
                        "#8c8c8c",
                        "#8c8c8c",
                        "#8c8c8c",
                        "#8c8c8c",
                        "#8c8c8c",
                    ],
                },
            },
            categories: monthRange(),
        },

        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                },
            },
        },
    },
};


function VisitChart({organization_id}) {
    const { Title, Paragraph } = Typography;
    const [series, setSeries] = useState([])
    const [lwp, setLwp] = useState({})


    const getVisitChart = () => {
        organizationStatsRequest(organization_id, 'customer-visits')
            .then((res) => {
                console.log(res)
                setSeries(res.office_stats)
                let sign = "+"
                if (res.lwp.type == "fall") {
                    sign = "-"
                }
                setLwp({
                    type: res.lwp.type,
                    sign: sign,
                    percent: res.lwp.percent
                })
            })
            .catch((errors) => {
                console.log(errors)
            })
    }

    useEffect(() => {
        // getDashboardData().then();
        getVisitChart()
    }, [])

    return (
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                <div className="linechart">
                    <div>
                        <Title level={5}>Customer visits</Title>
                        <Paragraph className="lastweek">
                            than last week <span className={lwp.type === 'rise' ? "bnb2": "text-danger"}>{lwp.sign}{lwp.percent}</span>
                        </Paragraph>
                    </div>
                    <div className="sales">
                        <ul>
                            <li>{<MinusOutlined />} Traffic</li>
                            <li>{<MinusOutlined />} Sales</li>
                        </ul>
                    </div>
                </div>

                <ReactApexChart
                    className="full-width"
                    options={data.options}
                    series={series}
                    type="area"
                    height={350}
                    width={"100%"}
                />
            </Card>
            </Col>
        </Row>
    );
}

export default VisitChart;
