import {Tabs} from "antd";
import {
    DollarCircleOutlined,
    PieChartOutlined, SettingOutlined,
} from "@ant-design/icons";
import Configurations from "./configurations";
import Statistics from "./stats";
import Pricing from "./pricing";

const OrganizationOverView = ({organization_id}) => {

    const tabs_items = [
        {
            label: (
                <span>
                    <PieChartOutlined /> Statistics
                </span>),
            key: 1,
            children: (<Statistics organization_id={organization_id} />)
        },
        {
            label: (
                <span>
                    <SettingOutlined /> Configurations
                </span>),
            key: 2,
            children: (<Configurations />)
        },
        {
            label: (
                <span>
                    <DollarCircleOutlined /> Pricing and Plans
                </span>),
            key: 3,
            children: (<Pricing />)
        }
    ]


    return (
        <>
            <Tabs
                defaultActiveKey="1"
                items={tabs_items}
            />
        </>
    )

}

export default OrganizationOverView
